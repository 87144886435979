<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" class="pt-5 pl-2">
          <h4 class="title">Absen Siswa</h4>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>

           

            <v-dialog v-model="konfirmHapus" max-width="400px">
              <v-card>
                <v-card-title>
                <span>Konfirmasi</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                  <span>Anda yakin menghapus data ini?</span>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
                  <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogUlang" max-width="500px">
              <v-card>
                <v-card-title>
                  <span>Set Absen {{jenis_absensi==0 ? "Masuk" : "Pulang"}}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                  <v-row>
                    <v-col  cols="12" sm="12" md="12">
                      Tanggal Absen : {{tgl_absen}}
                    </v-col>
                    <v-col  cols="12" sm="12" md="12">
                      Jam Absen : <input type="time" v-model="jam" format="hh:mm"  placeholder="Jam">
                    </v-col>
                    
                    <v-col  cols="12" sm="12" md="12">
                      <v-select item-text="nm_absen" item-value="j_absen" :items="lsJ_absen" label="Kehadiran" 
                      v-model="j_absen" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field label="Keterangan" v-model="ket"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="cyan" @click="dialogUlang = false">Batal</v-btn>
                  <v-btn color="cyan" @click="saveUlang()">Ya</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
              <v-row h-align="center" class="px-5 mx-auto mt-20" v-if="st_menu=='list'">
                <v-col  cols="6" sm="3" md="3">
                  <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="lsKelasLokal" label="Lokal"
                    v-model="kelas_lok_id" required v-on:change="fnData()"> </v-select>
                </v-col>
                <v-col  cols="6" sm="3" md="3">
                  <input type="date" v-model="tgl_absen" format="DD/MM/YYYY" v-on:change="fnData()" placeholder="Tanggal" style='margin-top:20px;'>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
                </v-col>
                <!--
                <v-col cols="3" md="3">
                  <v-btn @click="fnExcel()" small>Excel</v-btn>
                </v-col>
                -->

                <v-col cols="12" md="3" v-if="selectedHasil.length>0">
                  <v-btn color="green" class="mb-0" @click="openAbsensi(0)" small>
                      <v-icon>mdi-autorenew</v-icon>Set Absen Masuk
                    </v-btn>
                </v-col>
                <v-col cols="12" md="3" v-if="selectedHasil.length>0">
                  <v-btn color="red" class="mb-0" @click="openAbsensi(1)" small>
                      <v-icon>mdi-autorenew</v-icon>Set Absen Pulang
                    </v-btn>
                </v-col>

                <v-col cols="12" sm="12">
                  <v-card class="mx-auto" tile>
                    <!-- item-key="sis_ujian_id"-->
                    <v-data-table
                      :headers="hdUjian"
                      :items="ftData"
                      :disable-pagination="false"
                      :hide-default-footer="false"
                      :single-select="false"
                      item-key="siswa_id"
                      show-select
                      v-model="selectedHasil"
                      :loading="loadingHasil"
                      loading-text="Loading... Please wait"
                      :footer-props="{
                        'items-per-page-options': [30, 40, 50]
                      }"
                      :items-per-page="30"
                    >
                      <template v-slot:[`item.nilai`]="{ item }">
                        <div v-if="item.sis_tugas">
                          {{ item.nilai}}
                          <v-icon class="mr-2" @click="fnNilai(item.sis_tugas,item.nilai)" v-if="item.nm_file">mdi-pencil</v-icon>
                        </div>
                      </template>
                      <template v-slot:[`item.absenmasuk`]="{ item }">
                        <div v-if="item.nm_absen_masuk!==null"> ({{ item.nm_absen_masuk}}) <span v-if="item.j_absen_masuk=='1'">{{ item.jam_masuk }}</span> </div>

                      </template>
                      <template v-slot:[`item.absenpulang`]="{ item }">
                        <div v-if="item.nm_absen_pulang!==null"> ({{ item.nm_absen_pulang}}) <span v-if="item.j_absen_pulang=='1'">{{ item.jam_pulang }}</span> </div>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <div v-if="item.sis_tugas">
                          <v-icon class="mr-2" @click="fnView(item.nm_file)" v-if="item.nm_file">mdi-eye</v-icon>
                        </div>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import XLSX from 'xlsx';
//import readXlsxFile from 'read-excel-file';
import "vue-wysiwyg/dist/vueWysiwyg.css";

export default {
  data: () => ({
    snackbar: false,st_menu:'list',j_absen:null,ket:'',
    lsStatus:true, tgl1:new Date(),
    ar_mapel:[], text_cari:'', video:'', dialogNilai:false,
    text: 'OK',lokal_kelas_ujian:[],kelas_lok_id:null,tgl_absen:(new Date().toISOString().slice(0,10)
),
    timeout: 2000,text_cari_sis:'',loadingHasil:false,
    title:'',nilai:'',
    loading: true, fileGbr:'', dialogUlang:false,jenis_absensi:0,jam:0,
    lsData : [], ftKelasLokal:[],lsJ_absen:[],
    ftData : [],lsKelasLokal :[], kelasLokal_id : [],
    dialog : false,selectedHasil :[],
    menuKat : false, kelas_ujian_id:null,
    dialogImpor:false,sis_tugas:null,
    searchTextsLoading: true,
    judul: '',
    kompetensi: '',
    myHTML:'',
    yt_id: '',
    id: null,
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null, lsTugasSiswa:[],ftTugasSiswa:[],
    mapel_id:'',
    kelas_id:1,
    guru_id:0, ta_id:0,
    semester_id:0, lsGuru:[],
    lsKelas:[],
    lsTA:[],
    lsJPerangkat:[],
    j_perangkat_id:'',id_hapus:0,konfirmHapus:false,
    image: {
    uploadURL: "/api/myEndpoint",
    dropzoneOptions: {}
  },

  // limit content height if you wish. If not set, editor size will grow with content.
  maxHeight: "500px",
  ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
  headers: [
    { text: "No", width:"20", value: "nomor", sortable: false },
    { text: "Judul", align: "start",width:"40%", sortable: false, value: "judul" },
    { text: "Mata Pelajaran", value: "nm_mapel", sortable: false },
    { text: "Kelas", value: "nm_kelas", sortable: false },
    { text: "Hasil", value: "cek_lokal", sortable: false },
    { text: "Aksi", value: "actions", sortable: false },
  ],
  hdUjian: [
    { text: "Nama Siswa", value: "nama", sortable: false },
    { text: "Absen Masuk", value: "absenmasuk", sortable: false },
    { text: "Keterangan", value: "ket_masuk", sortable: false },
    { text: "Absen Pulang", value: "absenpulang", sortable: false },
    { text: "Keterangan", value: "ket_pulang", sortable: false },
    //{ text: "Koreksi", value: "actions", sortable: false },
  ],
  }),
  components:{
    Navbar
  },
  methods: {
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.nama.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    fnNilai(id,nilai){
      this.nilai = nilai
      this.dialogNilai = true
      this.sis_tugas = id
      //console.log('cek ', id)
    },
    openAbsensi(jenis){
      var d = new Date();
      let hour = d.getHours();
      let minutes  = d.getMinutes();
      hour = (hour < 10 ? "0" : "") + hour;
      minutes = (minutes < 10 ? "0" : "") + minutes;
      this.jam = hour+":"+minutes;
      this.jenis_absensi = jenis
      this.dialogUlang = true
    },
    saveUlang () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        ket:this.ket,
        j_absen:this.j_absen,
        jam: this.jam,
        jenis_absensi: this.jenis_absensi,
        tgl_absen:this.tgl_absen,
        data: this.selectedHasil
      }
      console.log('cek data ', dataku)
      axios
        .post(api + 'saveAbsenSiswaAdm2', dataku)
        .then(response => {
          console.log('cek data ', response.data.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.dialogUlang = false
            this.fnData()          
          } else {
            console.log(' tidak sukses ')
          }
        })

    },
    async fnHasil(id) {
      this.lsTugasSiswa = []
      this.ftTugasSiswa = []
      console.log('cek data ' , this.lsData) 
      const cekIndex = this.lsData.findIndex(cek => cek.gt_id === id)
      const dtEdit = this.lsData[cekIndex]
      console.log('cek id ', dtEdit)
      this.id = id
      let datax = this.lsKelasLokal.filter(m => m.kelas_id.includes(dtEdit.kelas_id))
      
      this.lokal_kelas_ujian=[]
      datax.forEach((value ) => {
        let lokalx = dtEdit.lokalx
        lokalx.forEach((val ) => {
          let datab = value.kelas_lok_id
          if(datab === val){
            this.lokal_kelas_ujian.push(value)
          }
        })
      })
      this.st_menu = 'hasil'
      console.log('cek mn ', this.st_menu)
    },
    KeydownSis: function () {
      if(this.text_cari_sis.length>0){
        this.ftUjianSiswa = this.lsUjianSiswa.filter(m => m.nama.toUpperCase().includes(this.text_cari_sis.toUpperCase()))
      }else{
        this.ftUjianSiswa = this.lsUjianSiswa
      }
    },
    fnExcel() {
      const kelasku=this.lsKelasLokal.filter(m => m.kelas_lok_id.includes(this.kelas_ujian_id))
      const dataExcel = []
      const judul = [
        'Laporan Data Tugas ' + ' Kelas : ' + kelasku[0]['nm_lokal_kelas']
      ]
      dataExcel.push(judul)
      const dataku = [
        'No',
        'Nama Siswa',
        'Benar',
        'Salah',
        'Nilai'
      ]
      dataExcel.push(dataku)
      let i=1
      this.ftTugasSiswa.forEach((value ) => {
        const dataku = [
          i,value['nama'],
          value['benar'],
          value['salah'],
          value['nilai'],
          //value['nm_semester']
        ]
        dataExcel.push(dataku)
        i++
      })
      var wscols = [
        {wch:10},
        {wch:30},
        {wch:15},
        {wch:20},
        {wch:20},
        {wch:20}
      ];
      const data = XLSX.utils.json_to_sheet(dataExcel)
      const wb = XLSX.utils.book_new()
      data["!cols"] = wscols
      data["A1"]=''
      data["B1"]=''
      data["C1"]=''
      data["D1"]=''
      data["E1"]=""
      //console.log('cek data ku ' , dataExcel )
      XLSX.utils.book_append_sheet(wb, data, 'data Tugas')
      XLSX.writeFile(wb,'hasil_tugas.xlsx')
    }, 
    async fnJ_absen() {
      axios
        .get(api + 'lsJ_absen')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsJ_absen = response.data.data
          }
      })
    },
    gtKelas(){
      this.kelasLokal_id=[]
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.toUpperCase().includes(this.kelas_id.toUpperCase()))
    },
    fnKonfirmasi(id){
      this.id_hapus=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id_hapus
      }
      axios
        .post(api + 'delGTugas', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()
            //this.clsForm()
            
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
      })
    },
    async fnData () {
      this.lsData = []
      this.ftData = []
      console.log('cek')
      axios
        .get(api + 'SiswaAbsenHrIni2&sk_id=' + this.$store.state.user.sk_id + '&kelas_lok_id=' + this.kelas_lok_id + '&tgl_absen=' + this.tgl_absen)
        .then(response => {
          if (response.data.cek === 1) {
            //console.log('cek data siswa ', response.data.data)
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.id=0
      this.clsForm()
      this.gtKelas()
    },
    async fnImpor() {
      this.clsForm()
      this.dialogImpor=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.gt_id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.kelas_id = dtEdit.kelas_id
      this.judul = dtEdit.judul
      this.mapel_id = dtEdit.mapel_id
      this.semester_id = dtEdit.semester_id
      this.kelasLokal_id = dtEdit.lokalx
      this.fileGbr = dtEdit.file
      this.tgl1 = dtEdit.tgl
      this.jam = dtEdit.jam 
      this.video = dtEdit.video 
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.toUpperCase().includes(this.kelas_id.toUpperCase()))
      this.st_menu='add'
    },
    clsForm () {
      this.judul = ''
      this.fileGbr = ''
      this.mapel_id = this.ddMapel[0]['id']
      this.j_perangkat_id = this.lsJPerangkat[0]['j_perangkat_id']
      this.semester_id = this.ddSemester[0]['id']
      this.st_menu='add'
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
    this.fnKelasLokal()
    this.fnJ_absen()
  }
}
</script>

<style lang="scss" scoped></style>